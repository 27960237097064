import React, { Component } from 'react';
import { Table } from 'reactstrap';
import axios from 'axios';
import { Link } from 'react-router-dom'
import ReactLoading from "react-loading";
import numeral from 'numeral'
import { Empty } from 'antd';

require('./content.css');

class Content extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: null,
			isLoading: true,
			rank: "All",
			marketcap: "All",
			volumn: "All rank"
		}
	}

	componentWillMount() {
		axios.get(`https://api.bscexchange.finance/api/v1/fimarketcap/markets`).then(res => this.setState({ data: res.data, isLoading: false })).catch(error => console.log(error));
	}

	applyFilter() {
		const { rank, marketcap, volumn } = this.state;
		this.setState({ isLoading: true })
		axios.get(`https://api.bscexchange.finance/api/v1/fimarketcap/markets?rank=${rank}&marketcap=${marketcap}&volume=${volumn}`
		).then(res => this.setState({
			data: res.data, isLoading: false
		})).catch(error => console.log(error));
	}

	clearFilter() {
		this.setState({ rank: 'All', marketcap: 'All', volumn: 'allRank' })
		axios.get(`https://api.bscexchange.finance/api/v1/fimarketcap/markets`).then(res => this.setState({ data: res.data, isLoading: false }))
	}

	renderRankOptions = () => {
		const rankList = ["All rank", "10", "20", "50", "70", "100"]
		const rankListOptions = []
		rankList.map(item => rankListOptions.push(<option selected={this.state.rank === item} value={item}>{item}</option>))
		return rankListOptions
	}

	renderOptions = type => {
		const { marketcap, volumn } = this.state
		const optionList = [
			{
				value: "All", text: "All"
			},
			{
				value: "1", text: "$10B"
			},
			{
				value: "2", text: "$1B - $10B"
			},
			{
				value: "3", text: "$100M - $1B"
			},
			{
				value: "4", text: "$10M - $100M"
			},
			{
				value: "5", text: "$1M - $10M"
			}
		]
		const options = []
		optionList.map(item => options.push(<option selected={(type === 'marketcap' ? marketcap : volumn) === item.value} value={item.value}>{item.text}</option>))
		return options
	}

	renderMarketFilter = () => {
		return (
			<div class="marketFilter">
				<div class="row rowMarketFilter" >
					<div class="col-8">
						<div class="row">
							<div class="col-sm-4">
								<strong>
									Rank
								</strong> &nbsp;
								<select
									class="form-select"
									aria-label="Large select example"
									onChange={(event) => this.setState({ rank: event.target.value })}
								>
									{this.renderRankOptions()}
								</select>
							</div>
							<div class="col-sm-4">
								<strong>
									Market Cap
								</strong> &nbsp;
								<select
									class="form-select"
									aria-label="Large select example"
									onChange={(event) => this.setState({ marketcap: event.target.value })}
								>
									{this.renderOptions("marketcap")}
								</select>
							</div>
							<div class="col-sm-4">
								<strong>
									Volume
								</strong> &nbsp;
								<select
									class="form-select"
									aria-label="Large select example"
									onChange={(event) => this.setState({ volumn: event.target.value })}
								>
									{this.renderOptions()}
								</select>
							</div>
						</div>
					</div>
					<div class="col-2 "></div>
					<div class="col-2 filterButtonClass">
						<button onClick={() => this.clearFilter()} type="button" class="btn btn-primary button-style">
							Clear Filters
						</button>
						&nbsp;
						<button onClick={() => this.applyFilter()} type="button" class="btn btn-primary button-style">
							Apply Filter
						</button>
					</div>
				</div>
			</div>
		);
	}

	renderRow() {
		const { data } = this.state;
		if (data && data.length === 0) return <Empty />
		return data.map((item, k) => {
			return (
				<tr key={k}>
					<th scope="row">{item.rank}</th>
					<td>
						<img className="iconCoin" alt={item.image} src={item.image} />
						<Link key={k} to={`/tokendetail/${item.coin_name}/${item.id}`}>
							{item.name}
						</Link>
					</td>
					<td className="rightText">{numeral(item.market_cap).format('$0,0[.]00')}</td>
					<td className="rightText">{numeral(item.current_price).format('$0,0[.]00')}</td>
					<td className="rightText">{numeral(item.total_volume).format('$0,0[.]00')}</td>
					<td className="rightText">{numeral(item.circulating_supply).format('0,0.0000')} {item.symbol}</td>
					<td className="rightText">{item.price_change_percentage_24h}</td>
					<td className="rightText">{item.ath}</td>
					<td className="rightText">{item.atl}</td>
				</tr>
			)
		})
	}

	render() {
		const { isLoading, data } = this.state
		if (isLoading) return (
			<ReactLoading
				type="balls"
				color="#14112e"
				height={'10%'}
				width={'10%'}
				className="ReactLoading-style"
			/>
		)
		if (data) {
			return (
				<div className="container-home">
					{this.renderMarketFilter()}
					<Table>
						<thead>
							<tr>
								<th>#</th>
								<th>Name</th>
								<th className="rightText">Market Cap</th>
								<th className="rightText">Price</th>
								<th className="rightText">Volume (24h)</th>
								<th className="rightText">Circulating Supply</th>
								<th className="rightText">Change (24h)</th>
								<th className="rightText">All Time High</th>
								<th className="rightText">All Time Low</th>
							</tr>
						</thead>
						<tbody>
							{this.renderRow()}
						</tbody>
					</Table>
				</div>
			)
		}
	}
}

export default Content;