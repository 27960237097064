import React, { useEffect, useState } from 'react';

function BitcoinHalving() {
  const halvingInterval = 210000; // Blocks between halvings
  const [currentBlock, setCurrentBlock] = useState(808114); // Replace with the current block number

  // Function to calculate the time remaining until the next halving
  const calculateCountdown = () => {
    const blocksRemaining = halvingInterval - (currentBlock % halvingInterval);
    const secondsRemaining = blocksRemaining * 600; // 1 block every 10 minutes

    const days = Math.floor(secondsRemaining / (3600 * 24));
    const hours = Math.floor((secondsRemaining % (3600 * 24)) / 3600);
    const minutes = Math.floor((secondsRemaining % 3600) / 60);
    const seconds = secondsRemaining % 60;

    return `${days} days ${hours} hours ${minutes} minutes ${seconds} seconds`;
  };

  // State to store the countdown
  const [countdown, setCountdown] = useState(calculateCountdown());

  // Update the countdown every second
  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown(calculateCountdown());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <center>
        <h1>Bitcoin Halving Countdown</h1>
        <p>Time Remaining until the Next Halving:</p>
        <p>{countdown}</p>
      </center>
    </div>
  );
}

export default BitcoinHalving
