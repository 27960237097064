import React, { useState, useEffect } from "react";
import ReactHighstock from 'react-highcharts/ReactHighstock.src'
import axios from 'axios'
import './tokendetail.css';
import 'bootstrap/dist/js/bootstrap.min.js';

const TokenDetail = (props) => {
  const [config, setConfig] = useState('');
  const [tokendetail, setTokendetail] = useState('')

  useEffect(() => {
    axios(`https://api.coingecko.com/api/v3/coins/${props.match.params.coin}/market_chart?vs_currency=usd&days=100`)
      .then((response) => response.data)
      .then((data) => {
        setConfig({
          rangeSelector: {
            selected: 2,
          },

          plotOptions: {
            series: {
              compare: "percent",
              showInNavigator: true,
            },
          },

          tooltip: {
            pointFormat:
              '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.change}%)<br/>',
            valueDecimals: 3,
            split: true,
          },

          series: [
            {
              name: "Price USD",
              data: data.prices,
            },
          ],
        });
      });
    axios(
      `https://bscexchange.finance/api/v1/fimarketcap/markets/${props.match.params.id}`
    )
      .then((response) => {
        setTokendetail(response.data)
      }
      );
  }, []);

  return (
    <div>
      <div class="row ">
        <div class="col-8 pricetrading">
          <div class="charttrading">
            <div class="w-1/2 phone:w-full flex flex-wrap items-center nameCoin">
              <div class="">
                <div class="flex items-center">
                  <div>
                    <figure class="overflow-hidden w-[48px] rounded-full">
                      <img width="50px" height="50px"
                        src={tokendetail.image || "https://i.ibb.co/HK52Ptx/bsce-logo-removebg-preview.png"}
                        alt="BSCExchange"
                        class="w-full object-cover h-[48px]"
                      />
                    </figure>
                  </div>
                </div>
              </div>
              <div class="xl:w-10/12 sm:w-10/12 pl-4">
                <p class="text-textTitle text-[24px]">
                  {tokendetail.name}{" "}
                  <span class="text-textTitle uppercase text-[24px]">
                    {tokendetail.symbol}
                  </span>
                </p>
                <p class="text-textTitle text-[32px] items-center flex">
                  ${tokendetail.current_price}
                  <span class="ml-4 text-[#c95150] text-sm text-[20px]">
                    {tokendetail.price_change_percentage_24h}%
                  </span>
                </p>
              </div>
            </div>
            <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link active"
                  id="pills-home-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-home"
                  data-toggle="tab"
                  type="button"
                  role="tab"
                  href="#price-line"
                  aria-controls="pills-home"
                  aria-selected="true"
                >
                  Price
                </button>
              </li>
              {/* <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="pills-profile-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-profile"
                  href="#market-cap"
                  type="button"
                  data-toggle="tab"
                  role="tab"
                  aria-controls="pills-profile"
                  aria-selected="false"
                >
                  Market Cap
                </button>
              </li> */}
              {/* <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="pills-contact-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-contact"
                  href="#trading-view"
                  data-toggle="tab"
                  type="button"
                  role="tab"
                  aria-controls="pills-contact"
                  aria-selected="false"
                >
                  Trading View
                </button>
              </li> */}
            </ul>
            <div class="tab-content" id="pills-tabContent">
              <div
                class="tab-pane fade show active"
                id="price-line"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                {config ? <ReactHighstock config={config} /> : null}
              </div>
              <div
                class="tab-pane fade"
                id="market-cap"
                role="tabpanel"
                aria-labelledby="pills-profile-tab"
              >
                ...
              </div>
              <div
                class="tab-pane fade"
                id="trading-view"
                role="tabpanel"
                aria-labelledby="pills-contact-tab"
              >
                ...
              </div>
            </div>
          </div>
        </div>
        <div class="col-4 ">
          <div class="bg-backgroundChild rounded-lg p-6 border-dividerColorDefault border">
            <div class="border-b border-dividerColorDefault pb-6">
              <p class="text-[24px] text-textTitle"> Price Statistics</p>
            </div>
            <div class="w-full">
              <div class="flex w-full mt-6">
                <div class="w-4/12 text-[14px] text-textContentSpecial items-center">
                  {" "}
                  Price
                </div>
                <div class="w-8/12 text-[14px] text-backgroundActive items-center flex justify-end">
                  {tokendetail.current_price} USD
                </div>
              </div>
              <div class="flex w-full mt-6">
                <div class="w-4/12 text-[14px] text-textContentSpecial items-center">
                  Price Change (24h)
                </div>
                <div class="w-8/12 text-[14px] text-backgroundActive items-center flex justify-end">
                  <p>
                    <span class="ml-4 text-[#c95150] text-sm pr-2">
                      {tokendetail.price_change_percentage_24h} %
                    </span>
                  </p>
                  <p>{tokendetail.price_change_24h} USD</p>
                </div>
              </div>
              <div class="flex w-full mt-6">
                <div class="w-4/12 text-[14px] text-textContentSpecial items-center">
                  24h Low/24h High
                </div>
                <div class="w-8/12 text-[14px] text-backgroundActive items-center flex justify-end">
                  <div class="flex flex-wrap justify-end w-full pl-4">
                    <p class="whitespace-nowrap">
                      ${tokendetail.low_24h} USD /
                    </p>
                    <p class="whitespace-nowrap">
                      {" "}
                      ${tokendetail.high_24h} USD
                    </p>
                  </div>
                </div>
              </div>
              <div class="flex w-full mt-6">
                <div class="w-4/12 text-[14px] text-textContentSpecial items-center">
                  Market Cap
                </div>
                <div class="w-8/12 text-[14px] text-backgroundActive items-center flex justify-end">
                  ${tokendetail.market_cap}
                </div>
              </div>
              <div class="flex w-full mt-6">
                <div class="w-4/12 text-[14px] text-textContentSpecial items-center">
                  Market Cap Rank
                </div>
                <div class="w-8/12 text-[14px] text-backgroundActive items-center flex justify-end">
                  #{tokendetail.market_cap_rank}
                </div>
              </div>
              <div class="flex w-full mt-6">
                <div class="w-4/12 text-[14px] text-textContentSpecial items-center">
                  All Time High
                </div>
                <div class="w-8/12 text-[14px] text-backgroundActive items-center flex justify-end flex-wrap">
                  <div class="w-full flex justify-end flex-wrap gap-[4px]">
                    <p class="whitespace-nowrap">
                      <span class="text-[#c95150] text-sm ">
                        {tokendetail.ath_change_percentage}%
                      </span>{" "}
                    </p>
                    <p class="whitespace-nowrap">${tokendetail.ath} USD</p>
                  </div>
                  <div class="w-full flex justify-end">
                    <p class="text-[14px] text-textSub">
                      {tokendetail.ath_date}
                    </p>
                  </div>
                </div>
              </div>
              <div class="flex w-full mt-6">
                <div class="w-4/12 text-[14px] text-textContentSpecial items-center">
                  All Time Low
                </div>
                <div class="w-8/12 text-[14px] text-backgroundActive items-center flex justify-end flex-wrap">
                  <div class="w-full flex justify-end flex-wrap gap-[4px]">
                    <p class="whitespace-nowrap">
                      <span class="text-[#28a864] text-sm">
                        +{tokendetail.atl_change_percentage}%
                      </span>
                    </p>
                    <p class="whitespace-nowrap">${tokendetail.atl} USD</p>
                  </div>
                  <div class="w-full flex justify-end">
                    <p class="text-[14px] text-textSub">
                      {tokendetail.atl_date}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TokenDetail;
