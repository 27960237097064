import React, { Component } from 'react';
import NumberFormat from 'react-number-format';
import { Dropdown } from 'react-bootstrap';
import { Navbar, Nav, NavItem, NavLink } from 'reactstrap';
import axios from "axios"
import './header.css';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = { markets: null };
  }

  componentWillMount() {
    axios.get('https://api.coingecko.com/api/v3/global').then(res => this.setState({ markets: res.data }))
  }

  renderLogo = () => <a href="/"><img width={60} height={60} alt="logo" src="https://i.ibb.co/HK52Ptx/bsce-logo-removebg-preview.png" className="logo-image" /></a>

  renderMenu = () => {
    return (
      <Dropdown className="Dropdown-menu">
        <Dropdown.Toggle variant="success" className="Dropdown-menu" id="dropdown-basic">Financial MarketCap</Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item href="/">Home</Dropdown.Item>
          <Dropdown.Item target="_blank" href="https://t.me/huancapital">Token Listing</Dropdown.Item>
          <Dropdown.Item href="https://bscexchange.finance/">Exchange</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    )
  }

  renderNav = () => {
    const { markets = {} } = this.state;
    if (!markets) return null
    return (
      <Nav className="ml-auto" navbar>
        <NavItem className="">
          <NavLink>
            <span className="bold header-text">Market Cap: <NumberFormat className="header-text" displayType={'text'} thousandSeparator={true} prefix={'$'} value={Math.round(markets.data.total_market_cap.usd)} ></NumberFormat></span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink>
            <span className="bold header-text">24h Vol: <NumberFormat className="header-text" displayType={'text'} thousandSeparator={true} prefix={'$'} value={Math.round(markets.data.total_volume.usd)} ></NumberFormat></span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink>
            <span className="bold header-text">BTC Dominance: {Math.round(markets.data.market_cap_percentage.btc)}%</span>
          </NavLink>
        </NavItem>
      </Nav>
    )
  }

  render() {
    return (
      <div className="header blue shadow-bottom">
        <Navbar color="faded" light expand="md">
          {this.renderLogo()}
          {this.renderMenu()}
          {this.renderNav()}
        </Navbar>
      </div>
    );
  }
}

export default Header;