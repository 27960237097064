import React, { useState, useEffect } from 'react';
import './style.css';
import axios from 'axios';

const MarketOverview = () => {
  const [data, setData] = useState(null)

  useEffect(() => {
    axios.get(`https://api.coingecko.com/api/v3/global`).then(res => setData(res.data.data)).catch(error => console.log(error));
  }, []);

  if (!data) return null
  return (
    <div class="grid grid-cols-6 ipad:grid-cols-3 ipad:gap-4 phone:grid-cols-2 mt-6 mb-6 py-[32px] bg-backgroundChild rounded-lg border-dividerColorDefault border">
      <div class="w-full border-r border-r-dividerColor ipad:border-none pl-6 pr-[16px] relative col-span-1 ipadpro:flex ipadpro:flex-col ipadpro:justify-between">
        <p class="text-textContentSpecial text-[16px] phone:text-[12px]">
          Cryptos
        </p>
        <p class="text-textLink mt-[16px] text-[24px] font-black phone:text-[20px]">
          {data.active_cryptocurrencies}
        </p>
      </div>
      <div class="w-full border-r border-r-dividerColor ipad:border-none pl-6 pr-[16px] relative col-span-1 ipadpro:flex ipadpro:flex-col ipadpro:justify-between">
        <p class="text-textContentSpecial text-[16px] phone:text-[12px]">
          Exchange
        </p>
        <p class="text-textLink mt-[16px] text-[24px] font-black phone:text-[20px]">
          {data.markets}
        </p>
      </div>
      <div class="w-full border-r border-r-dividerColor ipad:border-none pl-6 pr-[16px] relative col-span-1 ipadpro:flex ipadpro:flex-col ipadpro:justify-between">
        <p class="text-textContentSpecial text-[16px] phone:text-[12px]">
          USDT Dominance
        </p>
        <p class="text-textLink mt-[16px] text-[24px] font-black phone:text-[20px]">
          {data.market_cap_percentage.usdt.toString().substring(0, 5)} %
        </p>
      </div>
      <div class="w-full border-r border-r-dividerColor ipad:border-none pl-6 pr-[16px] relative col-span-1 ipadpro:flex ipadpro:flex-col ipadpro:justify-between">
        <p class="text-textContentSpecial text-[16px] phone:text-[12px]">
          BNB Dominance
        </p>
        <p class="text-textLink mt-[16px] text-[24px] font-black phone:text-[20px]">
          {data.market_cap_percentage.bnb.toString().substring(0, 5)} %
        </p>
      </div>
      <div class="w-full border-r border-r-dividerColor ipad:border-none pl-6 pr-[16px] relative col-span-1 ipadpro:flex ipadpro:flex-col ipadpro:justify-between">
        <p class="text-textContentSpecial text-[16px] phone:text-[12px]">
          BTC Dominance
        </p>
        <p class="text-textLink mt-[16px] text-[24px] font-black phone:text-[20px]">
          {data.market_cap_percentage.btc.toString().substring(0, 5)} %
        </p>
      </div>
      <div class="w-full border-r border-r-dividerColor ipad:border-none pl-6 pr-[16px] relative col-span-1 ipadpro:flex ipadpro:flex-col ipadpro:justify-between border-none">
        <p class="text-textContentSpecial text-[16px] phone:text-[12px]">
          ETH Dominance
        </p>
        <p class="text-textLink mt-[16px] text-[24px] font-black phone:text-[20px]">
          {data.market_cap_percentage.eth.toString().substring(0, 5)} %
        </p>
      </div>
    </div>
  )
}

export default MarketOverview;