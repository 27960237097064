import React, { Component } from 'react';
import { Table } from 'reactstrap';
import axios from 'axios';
import ReactLoading from "react-loading";
import numeral from 'numeral'
import { Empty } from 'antd';

require('./style.css');

class Categories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      dataFilter: null,
      isLoading: true,
      rank: "All",
      marketcap: "All",
      volumn: "All rank"
    }
  }

  componentWillMount() {
    axios.get(`https://api.coingecko.com/api/v3/coins/categories/`).then(res => this.setState({ data: res.data, isLoading: false })).catch(error => console.log(error));
  }

  applyFilter() {
    const {
      rank,
      marketcap,
      volumn,
      data
    } = this.state
    let filterData = data
    if (rank !== "All") {
      filterData = filterData.slice(0, Number(rank));
    }
    if (marketcap !== "All") {
      if (marketcap === "1") {
        filterData = filterData.filter(item => item.market_cap >= 10000000000)
      }
      if (marketcap === "2") {
        filterData = filterData.filter(item => (item.market_cap >= 1000000000 && item.market_cap <= 10000000000))
      }
      if (marketcap === "3") {
        filterData = filterData.filter(item => (item.market_cap >= 100000000 && item.market_cap <= 1000000000))
      }
      if (marketcap === "4") {
        filterData = filterData.filter(item => (item.market_cap >= 10000000 && item.market_cap <= 100000000))
      }
      if (marketcap === "5") {
        filterData = filterData.filter(item => (item.market_cap >= 1000000 && item.market_cap <= 10000000))
      }
    }
    if (volumn !== "All") {
      if (volumn === "1") {
        filterData = filterData.filter(item => item.volume_24h >= 10000000000)
      }
      if (volumn === "2") {
        filterData = filterData.filter(item => (item.volume_24h >= 1000000000 && item.volume_24h <= 10000000000))
      }
      if (volumn === "3") {
        filterData = filterData.filter(item => (item.volume_24h >= 100000000 && item.volume_24h <= 1000000000))
      }
      if (volumn === "4") {
        filterData = filterData.filter(item => (item.volume_24h >= 10000000 && item.volume_24h <= 100000000))
      }
      if (volumn === "5") {
        filterData = filterData.filter(item => (item.volume_24h >= 1000000 && item.volume_24h <= 10000000))
      }
    }
    this.setState({ dataFilter: filterData })
  }

  clearFilter() {
    this.setState({ rank: 'All', marketcap: 'All', volumn: 'allRank' })
    this.setState({
      isLoading: true
    })
    axios.get(`https://api.coingecko.com/api/v3/coins/categories/`).then(res => {
      this.setState({
        data: res.data,
        isLoading: false,
        dataFilter: res.data
      })
    }).catch(error => console.log(error));
  }

  renderRankOptions = () => {
    const rankList = ["All rank", "5", "10", "20", "50", "70", "100"]
    const rankListOptions = []
    rankList.map(item => rankListOptions.push(<option selected={this.state.rank === item} value={item}>{item}</option>))
    return rankListOptions
  }

  renderOptions = type => {
    const { marketcap, volumn } = this.state
    const optionList = [
      {
        value: "All", text: "All"
      },
      {
        value: "1", text: "$10B"
      },
      {
        value: "2", text: "$1B - $10B"
      },
      {
        value: "3", text: "$100M - $1B"
      },
      {
        value: "4", text: "$10M - $100M"
      },
      {
        value: "5", text: "$1M - $10M"
      }
    ]
    const options = []
    optionList.map(item => options.push(<option selected={(type === 'marketcap' ? marketcap : volumn) === item.value} value={item.value}>{item.text}</option>))
    return options
  }

  renderMarketFilter = () => {
    return (
      <div class="marketFilter">
        <div class="row rowMarketFilter" >
          <div class="col-8">
            <div class="row">
              <div class="col-sm-4">
                <strong>
                  Rank
                </strong> &nbsp;
                <select
                  class="form-select"
                  aria-label="Large select example"
                  onChange={(event) => this.setState({ rank: event.target.value })}
                >
                  {this.renderRankOptions()}
                </select>
              </div>
              <div class="col-sm-4">
                <strong>
                  Market Cap
                </strong> &nbsp;
                <select
                  class="form-select"
                  aria-label="Large select example"
                  onChange={(event) => this.setState({ marketcap: event.target.value })}
                >
                  {this.renderOptions("marketcap")}
                </select>
              </div>
              <div class="col-sm-4">
                <strong>
                  Volume
                </strong> &nbsp;
                <select
                  class="form-select"
                  aria-label="Large select example"
                  onChange={(event) => this.setState({ volumn: event.target.value })}
                >
                  {this.renderOptions()}
                </select>
              </div>
            </div>
          </div>
          <div class="col-2 "></div>
          <div class="col-2 filterButtonClass">
            <button onClick={() => this.clearFilter()} type="button" class="btn btn-primary button-style">
              Clear Filters
            </button>
            &nbsp;
            <button onClick={() => this.applyFilter()} type="button" class="btn btn-primary button-style">
              Apply Filter
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderTopCoinImage = (listImage) => {
    const list = []
    listImage.map(item => {
      list.push(
        <span className='Categories-style-img'>
          <img className="iconCoin " alt={item.image} src={item} />
        </span>)
    })
    return list
  }

  renderRow() {
    const { data } = this.state;
    if (data && data.length === 0) return <Empty />
    const dataToRender = this.state.dataFilter ? this.state.dataFilter : data
    return dataToRender.map((item, k) => {
      return (
        <tr key={k}>
          <th scope="row">{item.trust_score_rank}</th>
          <td>
            <a href={`${item.url}`} target="_blank">
              {item.name}
            </a>
          </td>
          <td className="rightText">
            {
              this.renderTopCoinImage(item.top_3_coins)
            }
          </td>
          <td className="rightText">
            {
              numeral(item.volume_24h).format('$0,0[.]00')
            }
          </td>
          <td className="rightText">
            {
              numeral(item.market_cap).format('$0,0[.]00')
            }
          </td>
          <td className="rightText">
            {
              item.content
            }
          </td>
        </tr>
      )
    })
  }

  render() {
    const { isLoading, data } = this.state
    if (isLoading) return (
      <ReactLoading
        type="balls"
        color="#14112e"
        height={'10%'}
        width={'10%'}
        className="ReactLoading-style"
      />
    )
    if (data) {
      return (
        <div className="container-home">
          {this.renderMarketFilter()}
          <Table>
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th className="rightText">
                  Top Gains
                </th>
                <th className="rightText">
                  Volumn 24h
                </th>
                <th className="rightText">
                Market Capitalization	
                </th>
                <th className="rightText">Content</th>
              </tr>
            </thead>
            <tbody>
              {this.renderRow()}
            </tbody>
          </Table>
        </div>
      )
    }
  }
}

export default Categories;
