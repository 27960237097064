import React, { Component } from 'react';
import { Table } from 'reactstrap';
import axios from 'axios';
import ReactLoading from "react-loading";
import { Empty } from 'antd';

require('./style.css');

class FutureExchange extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: null,
			filterData: null,
			isLoading: true,
			rank: "All",
			marketcap: "All",
			volumn: "All rank"
		}
	}

	componentWillMount() {
		this.setState({
			isLoading: true
		})
		axios.get(
			`https://api.coingecko.com/api/v3/derivatives/exchanges`
		).then(res => {
			console.log("#########-FUTURE-TRADING =>", res.data)
			this.setState({ data: res.data, isLoading: false })
		}).catch(error => console.log(error));
	}

	applyFilter() {
    const { rank, data } = this.state
    let filterData = data
    if (rank !== "All") {
      filterData = filterData.slice(0, Number(rank));
    }
		this.setState({
			dataFilter: filterData
		})
	}

	clearFilter() {
		this.setState({ rank: 'All', marketcap: 'All', volumn: 'allRank' })
		this.setState({
			isLoading: true
		})
		axios.get(`https://api.coingecko.com/api/v3/derivatives/exchanges`).then(res => this.setState({
			data: res.data,
			isLoading: false,
			dataFilter: res.data
		}))
	}

	renderRankOptions = () => {
		const rankList = ["All rank", "5", "10", "20", "50", "70", "100"]
		const rankListOptions = []
		rankList.map(item => rankListOptions.push(<option selected={this.state.rank === item} value={item}>{item}</option>))
		return rankListOptions
	}

	renderOptions = type => {
		const { marketcap, volumn } = this.state
		const optionList = [
			{
				value: "All", text: "All"
			},
			{
				value: "1", text: "$10B"
			},
			{
				value: "2", text: "$1B - $10B"
			},
			{
				value: "3", text: "$100M - $1B"
			},
			{
				value: "4", text: "$10M - $100M"
			},
			{
				value: "5", text: "$1M - $10M"
			}
		]
		const options = []
		optionList.map(item => options.push(<option selected={(type === 'marketcap' ? marketcap : volumn) === item.value} value={item.value}>{item.text}</option>))
		return options
	}

	renderMarketFilter = () => {
		return (
			<div class="marketFilter">
				<div class="row rowMarketFilter" >
					<div class="col-8">
						<div class="row">
							<div class="col-sm-4">
								<strong>
									Rank
								</strong> &nbsp;
								<select
									class="form-select"
									aria-label="Large select example"
									onChange={(event) => this.setState({rank: event.target.value})}
								>
									{ this.renderRankOptions() }
								</select>
							</div>
							{/* <div class="col-sm-4">
								<strong>
									Market Cap
								</strong> &nbsp;
								<select
									class="form-select"
									aria-label="Large select example"
									onChange={(event) => this.setState({marketcap: event.target.value})}
								>
									{ this.renderOptions("marketcap") }
								</select>
							</div>
							<div class="col-sm-4">
								<strong>
									Volume
								</strong> &nbsp;
								<select
									class="form-select"
									aria-label="Large select example"
									onChange={(event) => this.setState({volumn: event.target.value})}
								>
									{ this.renderOptions() }
								</select>
							</div> */}
						</div>
					</div>
					<div class="col-2 "></div>
					<div class="col-2 filterButtonClass">
						<button onClick={() => this.clearFilter()} type="button" class="btn btn-primary button-style">
							Clear Filters
						</button>
						&nbsp;
						<button onClick={() => this.applyFilter()} type="button" class="btn btn-primary button-style">
							Apply Filter
						</button>
					</div>
				</div>
			</div>
		);
	}

	renderRow() {
		// const { data } = this.state;
		const { data, dataFilter } = this.state;
		if (data && data.length === 0) return <Empty />
		const dataToRender = dataFilter ? dataFilter : data
		return dataToRender.map((item, k) => {
			return (
				<tr key={k}>
					<th scope="row">{item.trust_score_rank}</th>
					<td>
						<img className="iconCoin" alt={item.image} src={item.image} />
            <a href={`${item.url}`} target="_blank">
              {item.name}
            </a>
					</td>
					<td className="rightText">
            { item.year_established }
          </td>
					<td className="rightText">{item.trade_volume_24h_btc} BITCOIN</td>
					<td className="rightText">{item.open_interest_btc}</td>
					<td className="rightText">{item.number_of_perpetual_pairs}</td>
					<td className="rightText">{item.country}</td>
					<td className="rightText">{item.description || 'Cryptocurrency Exchange'}</td>
				</tr>
			)
		})
	}

	render() {
		const { isLoading, data } = this.state
		if (isLoading) return (
			<ReactLoading
				type="balls"
				color="#14112e"
				height={'10%'}
				width={'10%'}
				className="ReactLoading-style"
			/>
		)
		if (data) {
			return (
				<div className="container-home">
					{ this.renderMarketFilter() }
					<Table>
						<thead>
							<tr>
								<th>#</th>
								<th>Exchange_Name</th>
								<th className="rightText">Year_Established</th>
								<th className="rightText">Volume_(24h)</th>
								<th className="rightText">
									Open_Interest_BTC
								</th>
								<th className="rightText">
									Perpetual_Pairs
								</th>
								<th className="rightText">Country</th>
								<th className="rightText">Description</th>
							</tr>
						</thead>
						<tbody>
							{this.renderRow()}
						</tbody>
					</Table>
				</div>
			)
		}
	}
}

export default FutureExchange;
