import React, { Component, Fragment } from 'react';
import './style.css';
import { Button } from "react-bootstrap";

class MarketMenuList extends Component {
  constructor(props) {
    super(props);
    this.state = { data: null }
  }

  render() {
    return (
      <Fragment className="renderFilterList-style">
        {/* <center>
          <Button className="MarketMenuList-button-style" onClick={() => window.location.replace("/")}>
            All Markets
          </Button>
          <Button onClick={() => window.location.replace("/future-exchange")} className="MarketMenuList-button-style">
            Future Exchange
          </Button>
          <Button className="MarketMenuList-button-style" onClick={() => window.location.replace("/exchanges")} >
            Exchange List
          </Button>
          <Button onClick={() => window.location.replace("/categories")} className="MarketMenuList-button-style">
            Categories
          </Button>
        </center> */}
      </Fragment>
    )
  }
}

export default MarketMenuList;