import React, { Component } from 'react';
import { Table } from 'reactstrap';
import axios from 'axios';
import ReactLoading from "react-loading";
import { Empty } from 'antd';

require('./style.css');

class Exchanges extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: null,
			dataFilter: null,
			isLoading: true,
			rank: 'All'
		}
	}

	componentWillMount() {
		axios.get(`https://api.coingecko.com/api/v3/exchanges`).then(res => this.setState({ data: res.data, isLoading: false })).catch(error => console.log(error));
	}

	applyFilter() {
    const { rank, data } = this.state
    let filterData = data
		if (rank === 'All') return
		this.setState({ isLoading: true, dataFilter: filterData.slice(0, Number(rank)) });
		setInterval(() => this.setState({ isLoading: false }), 500);
	}

	clearFilter() {
		this.setState({ rank: 'All', isLoading: true, dataFilter: this.state.data })
		setInterval(() => this.setState({ isLoading: false }), 500);
	}

	renderRankOptions = () => {
		const rankList = ["All rank", "10", "20", "50", "70", "100"]
		const rankListOptions = []
		rankList.map(item => rankListOptions.push(<option selected={this.state.rank === item} value={item}>{item}</option>))
		return rankListOptions
	}

	renderMarketFilter = () => {
		return (
			<div class="marketFilter">
				<div class="row rowMarketFilter" >
					<div class="col-8">
						<div class="row">
							<div class="col-sm-4">
								<strong>
									Rank
								</strong> &nbsp;
								<select
									class="form-select"
									aria-label="Large select example"
									onChange={(event) => this.setState({rank: event.target.value})}
								>
									{ this.renderRankOptions() }
								</select>
							</div>
						</div>
					</div>
					<div class="col-2 "></div>
					<div class="col-2 filterButtonClass">
						<button onClick={() => this.clearFilter()} type="button" class="btn btn-primary button-style">
							Clear Filters
						</button>
						&nbsp;
						<button onClick={() => this.applyFilter()} type="button" class="btn btn-primary button-style">
							Apply Filter
						</button>
					</div>
				</div>
			</div>
		);
	}

	renderRow() {
		const { data, dataFilter } = this.state;
		if (data && data.length === 0) return <Empty />
		const dataToRender = dataFilter ? dataFilter : data
		return dataToRender && dataToRender.map((item, k) => {
			return (
				<tr key={k}>
					<th scope="row">{item.trust_score_rank}</th>
					<td>
						<img className="iconCoin" alt={item.image} src={item.image} />
            <a href={`${item.url}`} target="_blank">
              {item.name}
            </a>
					</td>
					<td className="rightText">
            { item.year_established }
          </td>
					<td className="rightText">
            {
              item.trust_score
            }
          </td>
					<td className="rightText">{item.trade_volume_24h_btc} BITCOIN</td>
					<td className="rightText">{item.trust_score_rank}</td>
					<td className="rightText">{item.country}</td>
					<td className="rightText">{item.description || 'Crypto Exchange (Bitcoin and other cryptocurrencies)'}</td>
				</tr>
			)
		})
	}

	render() {
		const { isLoading, data } = this.state
		if (isLoading) return (
			<ReactLoading
				type="balls"
				color="#14112e"
				height={'10%'}
				width={'10%'}
				className="ReactLoading-style"
			/>
		)
		if (data) {
			return (
				<div className="container-home">
					{ this.renderMarketFilter() }
					<Table>
						<thead>
							<tr>
								<th>#</th>
								<th>Exchange_Name</th>
								<th className="rightText">Year_Established</th>
								<th className="rightText">Trust_Score</th>
								<th className="rightText">Volume (24h)</th>
								<th className="rightText">Trust_Score_Rank</th>
								<th className="rightText">Country</th>
								<th className="rightText">Description</th>
							</tr>
						</thead>
						<tbody>
							{this.renderRow()}
						</tbody>
					</Table>
				</div>
			)
		}
	}
}

export default Exchanges;
