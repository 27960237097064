import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route } from "react-router-dom";
import registerServiceWorker from './registerServiceWorker';
import Header from './header/header';
import HomeMarket from './content/content';
import MarketOverview from './market_overview/index.js';
import TokenDetail from './tokendetail/tokendetail'
import Exchanges from './filterpage/exchanges/index.js';
import FutureExchange from './filterpage/future_exchange/index.js';
import Categories from './filterpage/categories/index.js';
import MarketMenuList from './market_menu_list';
import BitcoinHalving from './filterpage/bitcoin_halving/index.js';

const Pages = () => (
	<Router>
		<div>
			<Header />
			<div className='container'>
				<MarketOverview/>
				<MarketMenuList />
				<Route exact path="/tokendetail/:coin/:id" component={TokenDetail}/>
				<Route exact path="/" component={HomeMarket} />
				<Route exact path="/exchanges" component={Exchanges} />
				<Route exact path="/future-exchange" component={FutureExchange} />
				<Route exact path="/categories" component={Categories} />
				<Route exact path="/bitcoin-halving" component={BitcoinHalving} />
			</div>
		</div>
	</Router>
)

ReactDOM.render(<Pages />, document.getElementById('root'));
registerServiceWorker();
